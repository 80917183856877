/** @jsx jsx */
import { css } from "@emotion/react";
import bg8 from "../images/lp/sobre/bg-sobre.png";

export const sobreBanner = css`
  position: relative;
  min-height: 430px;
  background: url(${bg8}) no-repeat center center / cover;
  padding-top: 224px;
`

export const turmaBanner = css`
  position: relative;
  min-height: 430px;
  background: url(${bg8}) no-repeat center center / cover;
  padding-top: 224px;
`

export const bannerContent = css`
  position: relative;
  h1 {
    font-weight: 400;
    font-size: 56px;
    line-height: 120%;
    color: #312E2D;
    font-family: "normalidad-variable", sans-serif;
    font-variation-settings: "wght" 130, "wght" 600;
  }
`
